// eslint-disable-next-line no-shadow
export enum Transmit {
  APP_ID = 'weblogon',
  TICKET_COOKIE_NAME = 'ts_ticket_id',
  OTP_JOURNEY_NAME = 'OTP Journey',
  EXCEPTION_JOURNEY = 'exception_journey',
  QA_JOURNEY_NAME = 'Q&A',
  QA_FINAL_JOURNEY_ID = 'Q&A Common Journey',
  OTP_FINAL_JOURNEY_ID = 'otp_common_journey',
  OTP_ENROLLMENT = 'otp_enrollment',
  RETRIEVE_QUESTIONS = 'qa_retrieveQuestions',
  ENROLLMENT_APP_ID = 'webenrollment',
  QA_ENROLLMENT = 'enrollQuestions',
  RESET_QUESTIONS = 'resetQuestions',
  SELF_SERVICE = 'selfService',
}
// eslint-disable-next-line no-shadow
export enum TransmitErrorCode {
  InvalidInput = 1,
  AuthenticatorLocked = 2,
  AllAuthenticatorsLocked = 3,
  OtpExpired = 5,
  InitiateErrorCode = 6,
  ControlFlowExpired = 11,
  SessionRequired = 12,
}

// eslint-disable-next-line no-shadow
export enum TransmitAuthenticateResult {
  SUCCESSFUL,
  FAIL,
  LOCKOUT,
  RETRY,
  EXPIRED,
}

// eslint-disable-next-line no-shadow
export enum OtpAuthState {
  TargetSelection = 0,
  Input = 1,
}

// eslint-disable-next-line no-shadow
export enum TransmitQAValidateResult {
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  VALIDATION_SUCCESS = 'VALIDATION_SUCCESS',
}

// eslint-disable-next-line no-shadow
export enum TransmitValidateUserStatus {
  VERIFIED = 'Y',
  LOCKOUT = 'noRsaStatus',
  DELETED = 'D',
  UNVERIFIED = 'N',
  USER_VERIFIED = 'VERIFIED',
  USER_LOCKED = 'LOCKOUT',
  USER_STATUS_F = 'F',
  USER_STATUS_C = 'C',
}
export const TRANSMIT_TICKET_URL = 'ticketid'
export const NOT_APPLICABLE = 'na'
export const POLICY_ID = 'transmit_ticket'
export const TICKET_APP_ID = 'ticketservice'
export const JOURNEY_ID = 'collect_device_fp'
export const TRANSMIT_QA_URL = 'validateChallengeAnswer'
export const TRANSMIT_JWT_VALIDATION_URL = 'transmitJWTValidation'
export const ACCOUNT_ID = 'INST_LOGON'
export const SERVICE_ID = 'INST_LOGON'
export const LOGON_TRANSACTION_TYPE = 'LOGON'
export const OOB_CHALLENGE_TYPE = 'OOBA'
export const ENROLL_TRANSACTION_TYPE = 'ENROLLMENT'
export const OOB_ENROLL = 'enroll'
